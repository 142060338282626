// src/pages/account.js
import React from "react"

const Account = () => (
  <div>
    <p>This is going to be a protected route.</p>
  </div>
)

export default Account
